(function ($, undefined) {
  $('.card-link .tmb-carousel').on('click', function (e) {
    e.preventDefault()

    const a = $(this).find('a[href]')
    let target = '_self'

    if (typeof a.attr('rel') !== typeof undefined && a.attr('rel').indexOf('external') > -1 || e.ctrlKey) {
      target = '_blank'
    }

    // For modal link
    if (a.attr('href').substr(0, 1) == '#') {
      if (!$(e.target).is('a')) {
        // console.log(a)
        a.click()
      }
    } else {
      window.open(a.attr('href'), target)
    }
  })
}($))

$(document).ready(($) => {
  const ratingStar = $('.review_note .wpcf7-list-item')
  applyRating(1, ratingStar)
  ratingStar.click(function () {
    const rating = $(this).find('input').val()
    applyRating(rating, $(this))
  })

  ratingStar.hover(function () {
    const rating = $(this).find('input').val()
    applyRating(rating, $(this))
  }, function () {
    const rating = $(this).closest('.wpcf7-radio').find('.wpcf7-list-item input:checked').val()
    applyRating(rating, $(this))
  })
})

document.addEventListener('wpcf7mailsent', () => {
  resetRating()
})

function resetRating() {
  const ratingStar = $('.review_note .wpcf7-list-item')
  if (ratingStar) {
    applyRating(1, ratingStar)
  }
}

function applyRating(rating, obj) {
  const stars = obj.closest('.wpcf7-radio').find('.wpcf7-list-item')
  stars.removeClass('star-on')
  for (let i = 0; i < rating; i++) {
    $(stars[i]).addClass('star-on')
  }
}

import enquire from 'enquire.js';

(function ($, w, d, undefined) {
  enquire.register('screen and (max-width:1366px', {
    match: () => {
      $('.sc-publication-list').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 100,
        dots:true,
        arrows: false,
        centerMode: true,
        centerPadding : '10%',
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      })
    },
    unmatch: () => {
      let pubSlick = $('.sc-publication-list').slick()
      console.log(pubSlick)
      if(pubSlick) {
        pubSlick.slick('unslick')
      }
    }
  })
} ($, window, document))

// === BOOTSTRAP COMPONENTS
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/modal'

// === PLUGINS
import 'slick-carousel'

// === POLYFILL
import './polyfills/nodelist-foreach'

// === CF7 EFFECT
import '@nematis/cf7-effect'

// === UNCODE FIXES
import '@nematis/uncode-fixes'

// === CORE
import './core/eventGa'
import './core/card-links'

// === COMPONENTS
import './components/carousel'
import './components/review-form'

